var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"select",class:[{open: _vm.isOpen}, {
    'select_wrapper' : !_vm.customStyles,
    'has-error': !_vm.value && _vm.checkValidate,
  }],on:{"click":function($event){_vm.isOpen = !_vm.isOpen}}},[_c('div',{staticClass:"select_inner"},[_c('div',{class:['select_result', { 'select_result--label': !_vm.result && _vm.label }]},[_vm._v("\n      "+_vm._s(_vm.result || _vm.label)+"\n    ")]),_vm._v(" "),_c('img',{staticClass:"select_arrow",attrs:{"src":_vm.dropDownImage,"alt":"arrow"}})]),_vm._v(" "),(_vm.isOpen)?_c('div',{staticClass:"select_dropdown"},[_vm._l((_vm.items),function(item,index){return _c('div',{key:index,class:[
        'select_item',
        {'select_item--selected': _vm.inputValue ? item[_vm.inputValue] === _vm.value : item === _vm.value},
      ],on:{"click":function($event){$event.stopPropagation();return _vm.itemClick(item)}}},[_vm._v("\n      "+_vm._s(item.title)+"\n    ")])}),_vm._v(" "),_vm._t("inner-dropdown")],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }