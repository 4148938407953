<template>
  <div class="olga-block">
    <div class="olga-block__text">
      <h4>
        интервью с директором онлайн-школы: разрушаем мифы о&nbsp;домашнем обучении
      </h4>
      <p>
        мама ученицы пришла на&nbsp;интервью, чтобы&nbsp;задать вопросы
        по&nbsp;домашнему обучению <span>директору онлайн-школы</span>
      </p>
      <button
        class="m-btn"
        @click="openModal('olga')"
      >
        записаться на консультацию
      </button>
    </div>
    <button
      class="olga-block__image m-btn"
      @click="openVideo(['/v2/olga-block/video.webm', '/v2/olga-block/video.mp4'])"
    >
      <img
        src="/v2/olga-block/image.webp"
        alt="olga"
        class="olga-block__image_image"
      >
      <img
        src="/v2/olga-block/play.svg"
        alt="play"
        class="olga-block__image_play"
      >
    </button>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { MODAL_TYPES } from '~/components/v2/common/AppHeader/data';

export default {
  name: 'OlgaBlock',
  data: () => ({
    MODAL_TYPES,
  }),
  methods: {
    ...mapActions('appHeaderModals', [
      'openModal',
    ]),
    ...mapActions('modals', [
      'openVideo',
    ]),
  },
};
</script>

<style scoped lang="scss">

.olga-block {
  display: flex;
  gap: calc(var(--scale) * 20);
  margin-inline: auto;
  min-height: calc(var(--scale) * 538);
  max-width: calc(100vw - var(--scale) * 80);

  @include media-down($size-tablet) {
    max-width: calc(100vw - var(--scale) * 32);
    flex-direction: column-reverse;
    gap: calc(var(--scale) * 8);
  }

  @include media-down($size-mobile) {
    max-width: calc(100vw - var(--scale) * 24);
  }

  .olga-block__text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: calc(50% - var(--scale) * 10);
    padding: calc(var(--scale) * 32) calc(var(--scale) * 40) calc(var(--scale) * 40);

    background-color: #FFFFFF;
    background-image: url("/v2/olga-block/vector.svg");
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: right calc(var(--scale) * 110) center;
    border-radius: calc(var(--scale) * 40);

    @include media-down($size-tablet) {
      width: 100%;
      padding: calc(var(--scale) * 20) calc(var(--scale) * 8) calc(var(--scale) * 8);
      background-image: url("/v2/olga-block/vector-tablet.svg");
      background-position: right center;
      border-radius: calc(var(--scale) * 32);
    }

    @include media-down($size-mobile) {
      border-radius: calc(var(--scale) * 16);
      background-image: url("/v2/olga-block/vector-mobile.svg");
    }

    h4 {
      max-width: calc(var(--scale) * 713);
      margin-bottom: calc(var(--scale) * 20);

      font-weight: 500;
      font-size: calc(var(--scale) * 48);
      line-height: 95%;
      letter-spacing: -0.02em;
      color: #02120F;

      @include media-down($size-tablet) {
        margin-inline: calc(var(--scale) * 8);
        margin-bottom: calc(var(--scale) * 8);
        max-width: calc(var(--scale) * 535);
        font-size: calc(var(--scale) * 28);
      }
    }

    p {
      font-weight: 500;
      font-size: calc(var(--scale) * 26);
      line-height: 120%;
      color: #A7ABAC;

      span {
        color: #9C227C;
      }

      @include media-down($size-tablet) {
        margin-inline: calc(var(--scale) * 8);
        max-width: calc(var(--scale) * 433);
        font-size: calc(var(--scale) * 18);
      }
    }

    button {
      margin-top: auto;
      width: 100%;
      height: calc(var(--scale) * 122);
      background-color: #9C227C;
      border-radius: calc(var(--scale) * 40);

      font-weight: 500;
      font-size: calc(var(--scale) * 32);
      line-height: 110%;
      color: #FFFFFF;

      @include media-down($size-tablet) {
        margin-top: calc(var(--scale) * 78);
        height: calc(var(--scale) * 80);
        border-radius: calc(var(--scale) * 24);
        font-size: calc(var(--scale) * 18);
        letter-spacing: -0.02em;
      }

      @include media-down($size-mobile) {
        margin-top: calc(var(--scale) * 110);
      }
    }
  }

  .olga-block__image {
    position: relative;
    width: calc(50% - var(--scale) * 10);
    border-radius: calc(var(--scale) * 40);
    overflow: hidden;

    @include media-down($size-tablet) {
      width: 100%;
      border-radius: calc(var(--scale) * 32);
      height: calc(var(--scale) * 435);
    }

    @include media-down($size-mobile) {
      border-radius: calc(var(--scale) * 16);
      height: calc(var(--scale) * 216);
    }

    &_image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &_play {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: calc(var(--scale) * 111);

      @include media-down($size-tablet) {
        width: calc(var(--scale) * 60);
      }
    }
  }
}

</style>
