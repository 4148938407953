<template>
  <div class="synergy-friends">
    <h4>учись в школе и&nbsp;получи айфон <span>бесплатно</span></h4>
    <button
      class="m-btn"
      @click="openModal(MODAL_TYPES.synergyFriends)"
    >
      узнать больше
    </button>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { MODAL_TYPES } from '~/components/v2/common/AppHeader/data';

export default {
  name: 'SynergyFriends',
  data: () => ({
    MODAL_TYPES,
  }),
  methods: {
    ...mapActions('appHeaderModals', {
      openModal: 'openModal',
    }),
  },
};
</script>

<style scoped lang="scss">

.synergy-friends {
  width: calc(100vw - var(--scale) * 80);
  height: calc(var(--scale) * 350);
  margin-bottom: calc(var(--scale) * 128);
  margin-inline: auto;
  padding: calc(var(--scale) * 16) calc(var(--scale) * 40) calc(var(--scale) * 40);

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  border-radius: calc(var(--scale) * 60);
  background-image: url("/v2/common/synergy-friends/desktop.webp");
  background-size: cover;
  background-position: center;

  @include media-down($size-tablet) {
    width: calc(100vw - var(--scale) * 32);
    height: calc(var(--scale) * 314);
    margin-bottom: calc(var(--scale) * 76);
    padding: calc(var(--scale) * 20) calc(var(--scale) * 9) calc(var(--scale) * 9);
    border-radius: calc(var(--scale) * 24);
    background-image: url("/v2/common/synergy-friends/tablet.webp");
  }

  @include media-down($size-mobile) {
    width: calc(100vw - var(--scale) * 24);
    height: calc(var(--scale) * 512);
    padding: calc(var(--scale) * 20) calc(var(--scale) * 8) calc(var(--scale) * 8);
    background-image: url("/v2/common/synergy-friends/mobile.webp");
  }

  h4 {
    max-width: calc(var(--scale) * 1060);
    font-family: "Onest", sans-serif;
    font-weight: 500;
    font-size: calc(var(--scale) * 86);
    line-height: 90%;
    letter-spacing: -0.04em;
    color: #FFFFFF;

    @include media-down($size-tablet) {
      margin-inline: calc(var(--scale) * 8);
      max-width: calc(var(--scale) * 442);
      font-size: calc(var(--scale) * 40);
    }

    span {
      background: linear-gradient(90deg, #FFED5E 0%, #FEC72C 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }

  button {
    margin-top: auto;
    width: calc(var(--scale) * 341);
    height: calc(var(--scale) * 122);
    background: linear-gradient(90deg, #FFED5E 0%, #FEC72C 100%);
    box-shadow:
      calc(var(--scale) * 10) calc(var(--scale) * 10) calc(var(--scale) * 50) rgba(7, 21, 57, 0.1),
      calc(var(--scale) * 4) calc(var(--scale) * 4) calc(var(--scale) * 20) rgba(7, 21, 57, 0.05);
    border-radius: calc(var(--scale) * 40);

    font-family: "Onest", sans-serif;
    font-weight: 500;
    font-size: calc(var(--scale) * 32);
    line-height: 110%;
    color: #02120F;

    @include media-down($size-tablet) {
      width: calc(var(--scale) * 258);
      height: calc(var(--scale) * 80);
      border-radius: calc(var(--scale) * 24);

      font-weight: 500;
      font-size: calc(var(--scale) * 18);
      line-height: 110%;
      color: #02120F;
    }

    @include media-down($size-mobile) {
      width: 100%;
    }
  }
}

</style>
