import { setVisitSource, setVisitUtm } from '~/utils/visitHistory';

export default (({ app }) => {
  if (process.server) {
    return;
  }

  const referrer = document?.referrer;

  if (referrer && !referrer.includes('synergy.ru') && !referrer.includes('syndev.ru')) {
    setVisitSource(referrer);
  }

  function getUtmParameter(sParam) {
    const url = window.location.search.substring(1);
    const urlVariables = url.split('&');

    for (let i = 0; i < urlVariables.length; i += 1) {
      const variables = urlVariables[i].split('=');

      if (variables[0] === sParam) {
        return variables[1];
      }
    }

    return false;
  }

  const utmSource = getUtmParameter('utm_source');

  if (utmSource) {
    setVisitUtm(utmSource);
  }

  app.router.afterEach((to, from) => {
    if (!from?.name) {
      return;
    }
    if (from?.path === to?.path) {
      return;
    }

    if (!localStorage.getItem('internalTransitionsHistory')) {
      const history = [];
      localStorage.setItem('internalTransitionsHistory', JSON.stringify(history));
      return;
    }

    let history = JSON.parse(localStorage.getItem('internalTransitionsHistory'));
    if (!history.length) {
      history = [{
        datetime: new Date(),
        source_page: `https://school.synergy.ru${to.path}`,
      }];
      localStorage.setItem('internalTransitionsHistory', JSON.stringify(history));
      return;
    }
    if (history.length && history.length < 20) {
      history = [
        {
          datetime: new Date(),
          source_page: `https://school.synergy.ru${to.path}`,
        },
        ...history,
      ];
      localStorage.setItem('internalTransitionsHistory', JSON.stringify(history));
      return;
    }

    history = [
      {
        datetime: new Date(),
        source_page: `https://school.synergy.ru${to.path}`,
      },
      ...history.slice(0, 19),
    ];
    localStorage.setItem('internalTransitionsHistory', JSON.stringify(history));
  });
});
