import * as scheme from './scheme/materials.gql';

export default {
  getMaterials(variables = {}) {
    return this.query({
      query: scheme.GET_PUBLIC_MATERIALS,
      variables: {
        ...variables,
      },
    })
      .then((response) => response.data?.getPublicMaterials || {});
  },

  getVariantsMaterials(variables = {}) {
    return this.query({
      query: scheme.GET_PUBLIC_VARIANTS_MATERIALS,
      variables: {
        ...variables,
        page: 1,
      },
    })
      .then((response) => response.data?.getPublicMaterials || {});
  },

  getMaterialsStatistic(variables = {}) {
    return this.query({
      query: scheme.GET_PUBLIC_MATERIALS_STATISTIC,
      variables: {
        ...variables,
        page: 1,
        perPage: 8,
        kind: 'article',
      },
    })
      .then((response) => response.data?.getPublicMaterials || {});
  },

  // для сео нужно в url вывести slug материала, поэтому проверяется
  // id является slug (String) или id (Number)
  getMaterial(id) {
    return this.query({
      query: scheme.GET_PUBLIC_MATERIAL,
      variables: {
        [Number.isNaN(+id) ? 'slug' : 'id']: id,
      },
    })
      .then(({ data }) => data?.getPublicMaterial || {});
  },

  addViewMaterial(id) {
    return this.mutate({
      mutation: scheme.ADD_VIEW_PUBLIC_MATERIAL,
      variables: { id },
    });
  },

  // banners
  getBanners(params) {
    // eslint-disable-next-line no-param-reassign
    params.contentType = 'banner';
    return this.query({
      query: scheme.GET_BANNERS,
      variables: params,
    }).then((response) => response?.data?.getMarketingContents);
  },

  getPublicMaterialCategories() {
    return this.query({
      query: scheme.GET_PUBLIC_MATERIAL_CATEGORIES,
    })
      .then((response) => response.data?.getCategories || []);
  },

  manageReactionPublicMaterial(publicMaterialId, reaction, action) {
    return this.mutate({
      mutation: scheme.MANAGE_REACTION_PUBLIC_MATERIAL,
      variables: {
        action,
        publicMaterialId,
        reaction,
      },
    })
      .then(({ data }) => data?.manageReactionPublicMaterial || {});
  },

  getPublicLesson({ id, slug } = {}) {
    return this.query({
      query: scheme.GET_PUBLIC_LESSON,
      variables: { id, slug },
    }).then((response) => response?.data?.getPublicMaterial || {});
  },

  async manageUserParticipationInPublicWebinar(publicWebinarId, attendanceStatus) {
    const response = await this.mutate({
      mutation: scheme.MANAGE_USER_PARTICIPATION_IN_PUBLIC_WEBINAR,
      variables: {
        publicWebinarId,
        attendanceStatus,
      },
    });

    const result = response.data?.manageUserParticipationInPublicWebinar || {};

    if (result.success) {
      const user = await this.getMe(true);
      if (user) await this.app.store.dispatch('login', user);
    }

    return result;
  },
};
