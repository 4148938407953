<template>
  <div
    ref="select"
    :class="[{open: isOpen}, {
      'select_wrapper' : !customStyles,
      'has-error': !value && checkValidate,
    }]"
    @click="isOpen = !isOpen"
  >
    <div class="select_inner">
      <div :class="['select_result', { 'select_result--label': !result && label }]">
        {{ result || label }}
      </div>
      <img
        :src="dropDownImage"
        alt="arrow"
        class="select_arrow"
      >
    </div>
    <div
      v-if="isOpen"
      class="select_dropdown"
    >
      <div
        v-for="(item, index) in items"
        :key="index"
        :class="[
          'select_item',
          {'select_item--selected': inputValue ? item[inputValue] === value : item === value},
        ]"
        @click.stop="itemClick(item)"
      >
        {{ item.title }}
      </div>
      <slot name="inner-dropdown" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'VSelect',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    value: {
      type: [Object, String, Number],
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    customStyles: {
      type: Boolean,
      default: false,
    },
    inputValue: {
      type: String,
      default: null,
    },
    dropDownImage: {
      type: String,
      default: '/img/arrow_down_violet.svg',
    },
  },
  data: () => ({
    result: '',
    isOpen: false,
    checkValidate: false,
  }),
  watch: {
    value: {
      immediate: true,
      handler() {
        this.updateResult();
      },
    },
  },
  created() {
    if (!this.label) {
      this.updateResult();

      // не помню для чего нужен был setTimeout, поэтому пока оставлю
      setTimeout(() => {
        this.updateResult();
      });
    }
  },
  mounted() {
    document.addEventListener('click', this.clickOutside);
  },
  methods: {
    itemClick(item) {
      this.result = item.title;
      if (this.inputValue && item[this.inputValue]) {
        this.$emit('input', item[this.inputValue]);
        this.$emit('change', item[this.inputValue]);
      } else {
        this.$emit('input', item);
        this.$emit('change', item);
      }
      this.isOpen = false;
    },
    clickOutside(e) {
      const el = this.$refs.select;
      const { target } = e;
      if (el && el !== target && !el.contains(target)) {
        this.isOpen = false;
      }
    },

    updateResult() {
      if (this.inputValue) {
        const currentItem = this.items.find((item) => item[this.inputValue] === this.value);
        this.result = currentItem?.title || '';
      } else {
        this.result = this.value?.title || this.items?.[0]?.title || '';
      }
    },

    validate() {
      this.checkValidate = true;
      return Boolean(this.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.select_wrapper {
  border: 1px solid rgba(#949EAE, .24);
  background-color: $white-color;
  border-radius: 16px;
  padding: 20px 16px 17px;
  width: 200px;
  position: relative;
  z-index: 5;

  &.open {
    border-radius: 16px 16px 0 0;

    .select_arrow {
      transform-origin: center center;
      transform: rotate(180deg);
    }
  }
}

.select_inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.select_result {
  font-size: 16px;
}

.select_dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: $white-color;
  border-radius: 0 0 16px 16px;
  border: 1px solid rgba(#949EAE, .24);
  overflow: hidden;
}

.select_item {
  font: normal 500 16px/21px 'TildaSans', Arial, sans-serif;
  color: $black-color;
  padding: 12px 16px;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    background-color: $white-color-2;
  }
}
</style>
