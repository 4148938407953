<template>
  <div :class="['fieldset', { 'has-error': $v.name.$error && checkValidate }]">
    <input
      v-model="$v.name.$model"
      type="text"
      :placeholder="placeholder"
      :class="[{ round: roundBorder }]"
    >
    <template v-if="checkValidate">
      <div
        v-if="! $v.name.required && $v.name.$error"
        class="error-message"
      >
        {{ errorMessage ? errorMessage : 'Укажите свое имя' }}
      </div>
      <div
        v-else-if="!$v.name.onlyChars"
        class="error-message"
      >
        Имя может содержать только буквы
      </div>
      <div
        v-else-if="! $v.name.max && $v.name.$error"
        class="error-message"
      >
        Слишком длинное имя
      </div>
    </template>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'FormNameField',
  mixins: [validationMixin],
  props: {
    value: {
      type: String,
      default: '',
    },
    propValue: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Имя',
    },
    roundBorder: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: [String, null],
      default: null,
    },
  },
  data: () => ({
    name: '',
    checkValidate: false,
  }),
  watch: {
    propValue: {
      immediate: true,
      handler() {
        this.name = this.propValue;
      },
    },
    name(val) {
      this.$emit('input', val);
    },
  },
  mounted() {
    if (this.value) {
      this.name = this.value;
    }
  },
  methods: {
    validate() {
      this.$v.$touch();
      this.checkValidate = true;
      return !this.$v.name.$error;
    },
    reset() {
      this.checkValidate = false;
      this.name = '';
      this.$v.$reset();
    },
  },
  validations: {
    name: {
      required,
      onlyChars() {
        return /^[ a-zA-Zа-яА-Я]*$/.test(this.name);
      },
    },
  },
};
</script>

<style scoped lang="scss">

.fieldset {
  position: relative;

  &.has-error input {
    border-color: #FF0040 !important;
  }

  input.round {
    border-radius: 100px;
  }

  .error-message {
    color: #FF0040;
    text-align: right;
    font-size: 14px;
    line-height: 100%;
    position: absolute;
    top: 0;
    transform: translateY(-50%);
    right: 20px;
    padding: 0 5px;
    background-color: $white-color;
  }

  .round {
    border-radius: 100px !important;
  }
}

</style>
