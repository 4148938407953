import { gql } from '@apollo/client';

export const USER = gql`
  fragment userData on User {
    email
    id
    name
    phone
    registrationLeadId
  }
`;

export const USER_STUDENT = gql`
  fragment studentData on Student {
    email
    id
    name
    phone
    registrationLeadId

    publicWebinars {
      id

      currentMember {
        marketingLead {
          id
        }
      }
    }
  }
`;

export const USER_TEACHER = gql`
  fragment teacherData on Teacher {
    id
    email
    name
    phone
  }
`;

export const USER_TEACHER_CURATOR = gql`
  fragment teacherCuratorData on TeacherCurator {
    email
    id
    name
    phone
  }
`;

export const USER_SCHOOL_ADMIN = gql`
  fragment schoolAdminData on SchoolAdmin {
    id
    email
    name
    phone
  }
`;

export const USER_PARENT = gql`
  fragment parentData on Parent {
    email
    id
    name
    parentPhone
    phone
    registrationLeadId

    publicWebinars {
      id

      currentMember {
        marketingLead {
          id
        }
      }
    }
  }
`;

export const GET_ME = gql`
  query me {
    me {
      student {
        ...studentData
      }
      teacher {
        ...teacherData
      }
      teacherCurator {
        ...teacherCuratorData
      }
      schoolAdmin {
        ...schoolAdminData
      }
      parent {
        ...parentData
      }

      startedControlWorkLessonSessions {
        course {
          id
        }
        courseSessionId
      }
    }
  }
  ${USER_STUDENT}
  ${USER_TEACHER}
  ${USER_TEACHER_CURATOR}
  ${USER_SCHOOL_ADMIN}
  ${USER_PARENT}
`;

export const UPDATE_ACCOUNT = gql`
  mutation updateAccount($attributes: UserInput!) {
    updateAccount(attributes: $attributes) {
      errors {
        objectField
        message
        valid
      }
      student {
        ...studentData
      }
      parent {
        ...parentData
      }
      success
    }
  }
  ${USER_STUDENT}
  ${USER_PARENT}
`;

export const LOGIN = gql`
  mutation signIn(
    $email: String!
    $fingerprint: String!
    $password: String!
  ) {
    signIn(
      email: $email
      fingerprint: $fingerprint
      password: $password
    ) {
      student {
        ...studentData
      }
      teacher {
        ...teacherData
      }
      teacherCurator {
        ...teacherCuratorData
      }
      schoolAdmin {
        ...schoolAdminData
      }
      parent {
        ...parentData
      }
      user {
        ...userData
      }
      success
      errors {
        message
      }
    }
  }
  ${USER_STUDENT}
  ${USER_TEACHER}
  ${USER_TEACHER_CURATOR}
  ${USER_SCHOOL_ADMIN}
  ${USER_PARENT}
  ${USER}
`;

export const REGISTER = gql`
  mutation signUp(
    $classNumber: Int
    $comment: String
    $email: String!
    $emailMe: Boolean
    $fingerprint: String!
    $formTitle: String
    $landCode: String
    $name: String!
    $phone: String!
    $type: String!
    $utmData: JSON
    $visitHistory: [JSON!]
  ) {
    signUp(
      classNumber: $classNumber
      comment: $comment
      email: $email
      emailMe: $emailMe
      fingerprint: $fingerprint
      formTitle: $formTitle
      landCode: $landCode
      name: $name
      phone: $phone
      type: $type
      utmData: $utmData
      visitHistory: $visitHistory
    ) {
      directFlowToken
      success
      errors {
        message
        objectField
        valid
      }
      user {
        ...userData
      }
    }
  }
  ${USER}
`;

export const REMEMBER = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email, refferer: "school") {
      errors {
        field
        message
      }
      success
    }
  }
`;

/** ** Mutations **** */

export const LOGOUT = gql`
  mutation signOut($fingerprint: String!) {
    signOut(fingerprint: $fingerprint) {
      success
    }
  }
`;

export const SEND_LEAD = gql`
  mutation sendLead(
    $blankRegistration: Boolean,
    $email: String
    $formTitle: String
    $landCode: String!
    $latestComment: String
    $name: String
    $phone: String
    $utmData: JSON
    $visitHistory: [JSON!]
  ) {
    sendLead(
      blankRegistration: $blankRegistration
      email: $email
      formTitle: $formTitle
      landCode: $landCode
      latestComment: $latestComment
      name: $name
      phone: $phone
      utmData: $utmData
      visitHistory: $visitHistory
    ) {
      success
      errors {
        objectField
        message
        valid
      }
      lead {
        id
      }
    }
  }
`;

export const FINALIZE_LEAD = gql`
  mutation finalizeLead(
    $comment: String!
    $email: String!
    $formTitle: String
    $id: ID!
    $name: String!
    $phone: String!
    $visitHistory: [JSON!]
  ) {
    finalizeLead(
      comment: $comment
      email: $email
      formTitle: $formTitle
      id: $id
      name: $name
      phone: $phone
      visitHistory: $visitHistory
    ) {
      success
      errors {
        objectField
        message
        valid
      }
      lead {
        id
      }
    }
  }
`;

export const UPDATE_LEAD = gql`
  mutation updateLead(
    $id: ID!,
    $comment: String!,
  ) {
    updateLead(
      id: $id,
      comment: $comment,
    ) {
      success
      errors {
        objectField
        message
        valid
      }
    }
  }
`;

export const PURCHASE_PRODUCT = gql`
  mutation purchaseProduct(
    $callbackSuccess: String!
    $fastTrialFromCourseId: ID
    $productId: ID!
    $promoCode: String
    $startTrial: Boolean
  ){
    purchaseProduct (
      callbackSuccess: $callbackSuccess
      fastTrialFromCourseId: $fastTrialFromCourseId
      productId: $productId
      promoCode: $promoCode
      startTrial: $startTrial
    ) {
      errors {
        objectField
        message
        valid
      }
      firstTrialCourseSession {
        id
      }
      success
      link
    }
  }`;

export const EMAIL_SUBSCRIBE = gql`
  mutation sendInfoToEnkod(
    $email: String!
  ){
    sendInfoToEnkod (
      email: $email
    ) {
      errors {
        objectField,
        message,
        valid
      },
      success,
    }
  }`;

export const CONFIRM_EMAIL = gql`
  mutation confirmEmail($confirmationToken: String!){
    confirmEmail(confirmationToken: $confirmationToken) {
      errors {
        field
        message
        valid
      }
      success
    }
  }
`;

export const GET_ONBOARDING_QUESTIONS = gql`
  query getOnboardingQuestions ($questionnarieName: String!) {
    getOnboardingQuestions (questionnarieName: $questionnarieName) {
      group
      name
      position
      text
      questionnarieName
    }
  }
`;

export const GET_PRODUCTS = gql`
  query getProducts ($kind: String!) {
    getProducts (kind: $kind) {
      courses {
        description
        id
        subject {
          name
          slug
        }
        title
      }

      id
    }
  }
`;
