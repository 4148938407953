<template>
  <div class="wr-modal">
    <div
      :class="[
        'wr-modal__inner',
        {'wr-modal__inner--success': activeStep === 'success'},
      ]"
    >
      <button
        v-if="showPrevBtn"
        class="m-btn"
        @click="goToPrevStep"
      >
        <img
          src="/v2/modal/back-arrow.svg"
          alt="back"
          class="wr-modal__back"
        >
      </button>

      <button
        class="m-btn"
        @click="closeModal"
      >
        <img
          src="/v2/modal/close.svg"
          alt="close"
          class="wr-modal__close"
        >
      </button>

      <div
        v-if="showTabs"
        class="wr-modal__tabs"
      >
        <button
          v-for="item of formTabs"
          :key="item.value"
          :class="[
            'wr-modal__tabs--btn',
            {'wr-modal__tabs--btn--active': activeTab === item.value},
          ]"
          @click="activeTab = item.value"
        >
          {{ item.label }}
        </button>
      </div>

      <div
        v-if="activeTab === 'registration'"
        class="wr-modal__registration"
      >
        <h5
          v-if="modalTitle"
          class="wr-modal__title"
          v-html="modalTitle"
        />

        <div
          v-if="activeStep === 'role'"
          key="role"
          class="wr-modal__step"
        >
          <form-select-field
            :value="activeRole"
            :items="roles"
            placeholder="Выберите роль"
            :error="roleError"
            @update="activeRole = $event"
          />
        </div>

        <div
          v-if="activeStep === 'parent_info'"
          key="parent_info"
          class="wr-modal__step"
        >
          <form-name-field
            ref="parentNameField"
            v-model="parentName"
            class="form__input"
          />

          <form-phone-field
            ref="parentPhoneField"
            :value="parentPhone"
            :errors-list="registeredErrors"
            class="form__input form__input_phone"
            @updatePhone="parentPhone = $event"
            @clearSubmitError="clearSubmitError"
          />

          <form-email-field
            ref="parentEmailField"
            v-model="parentEmail"
            :submit-error="registeredErrors"
            class="form__input form__input_email"
            @clearSubmitError="clearSubmitError"
          />
        </div>

        <div
          v-if="activeStep === 'parent_child_info'"
          key="parent_child_info"
          class="wr-modal__step"
        >
          <form-select-field
            :value="parentChildParallel"
            :items="parallels"
            placeholder="Выберите параллель"
            :error="parentChildParallelError"
            @update="parentChildParallel = $event"
          />

          <form-name-field
            ref="parentChildNameField"
            v-model="parentChildName"
            class="form__input"
          />

          <form-email-field
            ref="parentChildEmailField"
            v-model="parentChildEmail"
            class="form__input form__input_email"
          />
        </div>

        <div
          v-if="activeStep === 'student_info'"
          key="student_info"
          class="wr-modal__step"
        >
          <form-select-field
            :value="studentParallel"
            :items="parallels"
            placeholder="Выберите параллель"
            :error="studentParallelError"
            @update="studentParallel = $event"
          />

          <form-name-field
            ref="studentNameField"
            v-model="studentName"
            class="form__input"
          />

          <form-phone-field
            ref="studentPhoneField"
            :value="studentPhone"
            :errors-list="registeredErrors"
            class="form__input form__input_phone"
            @updatePhone="studentPhone = $event"
            @clearSubmitError="clearSubmitError"
          />

          <form-email-field
            ref="studentEmailField"
            v-model="studentEmail"
            :submit-error="registeredErrors"
            class="form__input form__input_email"
            @clearSubmitError="clearSubmitError"
          />
        </div>

        <div
          v-if="activeStep === 'student_parent_info'"
          key="student_parent_info"
          class="wr-modal__step"
        >
          <form-name-field
            ref="studentParentNameField"
            v-model="studentParentName"
            class="form__input"
          />

          <form-phone-field
            ref="studentParentPhoneField"
            :value="studentParentPhone"
            class="form__input form__input_phone"
            @updatePhone="studentParentPhone = $event"
          />

          <form-email-field
            ref="studentParentEmailField"
            v-model="studentParentEmail"
            class="form__input form__input_email"
          />
        </div>

        <div
          v-if="activeStep === 'success'"
          class="wr-modal__success"
        >
          <h5>
            Вы успешно
            <br>
            зарегистрировались!
          </h5>

          <p>
            Вебинар состоится {{ webinarDate }}. За час до начала мероприятия
            мы отправим ссылку на вебинар на вашу электронную почту.
            А пока предлагаем вам подписаться на наш....
          </p>
        </div>

        <button
          v-if="btnText"
          class="btn"
          @click="goToNextStep"
        >
          {{ btnText }}
          <img
            src="/v2/modal/chevron-right.svg"
            alt="next"
          >
        </button>

        <p
          v-if="showLaw"
          class="wr-modal__law"
        >
          Нажимая на кнопку, вы соглашаетесь
          <br>
          <nuxt-link to="/">
            с условиями обработки данных
          </nuxt-link>
        </p>
      </div>

      <div
        v-if="activeTab === 'login'"
        class="wr-modal__login"
      >
        <login-form
          v-if="!rememberPassword"
          @login="login"
          @remember="rememberPassword = true"
        />

        <remember-password-form
          v-if="rememberPassword && !rememberSuccess"
          @success="rememberSuccess = true"
        />

        <remember-success
          v-if="rememberPassword && rememberSuccess"
          class="wr-modal__login--success"
        />
      </div>
    </div>

    <div
      class="wr-modal__overlay"
      @click="closeModal"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import pagesLand from '~/data/pagesLand';

import FormSelectField from '@/components/v2/common/fileds/FormSelectField.vue';
import FormNameField from '@/components/v2/common/fileds/FormNameField.vue';
import FormPhoneField from '@/components/v2/common/fileds/FormPhoneField.vue';
import FormEmailField from '@/components/v2/common/fileds/FormEmailField.vue';
import LoginForm from '@/components/v2/auth/LoginForm.vue';
import RememberPasswordForm from '@/components/v2/auth/RememberPasswordForm.vue';
import RememberSuccess from '@/components/v2/auth/RememberSuccess.vue';

export default {
  name: 'WebinarRegistrationModal',
  components: {
    FormSelectField,
    FormNameField,
    FormPhoneField,
    FormEmailField,
    LoginForm,
    RememberPasswordForm,
    RememberSuccess,
  },

  data: () => ({
    activeTab: 'registration',
    activeStep: 'role',
    rememberPassword: false,
    rememberSuccess: false,
    // roles
    activeRole: null,
    roleError: false,
    // parent way
    parentName: null,
    parentPhone: null,
    parentEmail: null,
    parentInfoValid: true,
    parentChildParallel: null,
    parentChildParallelError: false,
    parentChildName: null,
    parentChildEmail: null,
    parentChildInfoValid: true,
    // student way
    studentParallel: null,
    studentParallelError: null,
    studentName: null,
    studentPhone: null,
    studentEmail: null,
    studentInfoValid: true,
    studentParentName: null,
    studentParentPhone: null,
    studentParentEmail: null,
    studentParentInfoValid: true,
    // registered
    registeredErrors: [],
    registeredUser: null,
  }),
  computed: {
    ...mapGetters('modals', ['getRegistrationWebinarData']),
    formTabs() {
      return [
        { label: 'Регистрация', value: 'registration' },
        { label: 'Войти', value: 'login' },
      ];
    },
    roles() {
      return [
        { title: 'Родитель', value: 'parent' },
        { title: 'Ученик', value: 'student' },
      ];
    },
    parallels() {
      return [5, 6, 7, 8, 9, 10, 11].map((value) => ({ value, title: `${value} класс` }));
    },
    modalTitle() {
      switch (this.activeStep) {
        case 'parent_info':
          return 'Заполните информацию<br>о себе';
        case 'parent_child_info':
          return 'Заполните информацию<br>о своем ребенке';
        case 'student_info':
          return 'Заполните информацию<br>о себе';
        case 'student_parent_info':
          return 'Заполните информацию<br>о родителе';
        default:
          return null;
      }
    },
    btnText() {
      switch (this.activeStep) {
        case 'role':
        case 'parent_info':
        case 'student_info':
          return 'Далее';

        case 'parent_child_info':
        case 'student_parent_info':
          return 'Зарегистрироваться';
        default:
          return null;
      }
    },
    showTabs() {
      return this.activeStep !== 'success';
    },
    showLaw() {
      return [
        'parent_info',
        'parent_child_info',
        'student_info',
        'student_parent_info',
      ].includes(this.activeStep);
    },
    showPrevBtn() {
      if (this.activeTab === 'registration') {
        return !['role', 'success'].includes(this.activeStep);
      }

      return this.rememberPassword;
    },

    webinarHasStarted() {
      return new Date() > new Date(this.getRegistrationWebinarData.startDate);
    },
    webinarDate() {
      const date = new Date(this.getRegistrationWebinarData.startDate);

      const options = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        timeZone: 'Europe/Moscow',
      };

      return `${date.toLocaleString('ru-RU', options)}  по МСК`;
    },
  },
  watch: {
    activeTab(newVal) {
      if (newVal === 'login') {
        this.rememberPassword = false;
        this.rememberSuccess = false;
      }
      this.registeredErrors = [];
    },
    activeRole() {
      this.roleError = false;
    },
    parentChildParallel() {
      this.parentChildParallelError = false;
    },
    studentParallel() {
      this.studentParallelError = false;
    },
  },

  methods: {
    ...mapGetters({
      categories: 'getMaterialCategories',
    }),

    closeModal() {
      this.$store.dispatch('modals/closeWebinarRegistrationModal');
    },
    //
    validateParentInfo() {
      let valid = true;

      if (!this.$refs.parentNameField.validate()) {
        valid = false;
      }
      if (!this.$refs.parentPhoneField.validate()) {
        valid = false;
      }
      if (!this.$refs.parentEmailField.validate()) {
        valid = false;
      }

      this.parentInfoValid = valid;
    },
    validateParentChildInfo() {
      let valid = true;

      if (!this.$refs.parentChildNameField.validate()) {
        valid = false;
      }
      if (!this.$refs.parentChildEmailField.validate()) {
        valid = false;
      }
      this.parentChildParallelError = !this.parentChildParallel;
      if (this.parentChildParallelError) {
        valid = false;
      }

      this.parentChildInfoValid = valid;
    },
    validateStudentInfo() {
      let valid = true;

      this.studentParallelError = !this.studentParallel;
      if (this.studentParallelError) {
        valid = false;
      }

      if (!this.$refs.studentNameField.validate()) {
        valid = false;
      }
      if (!this.$refs.studentPhoneField.validate()) {
        valid = false;
      }
      if (!this.$refs.studentEmailField.validate()) {
        valid = false;
      }

      this.studentInfoValid = valid;
    },
    validateStudentParentInfo() {
      let valid = true;

      if (!this.$refs.studentParentNameField.validate()) {
        valid = false;
      }
      if (!this.$refs.studentParentPhoneField.validate()) {
        valid = false;
      }
      if (!this.$refs.studentParentEmailField.validate()) {
        valid = false;
      }

      this.studentParentInfoValid = valid;
    },
    clearSubmitError(field) {
      this.registeredErrors = this.registeredErrors.filter((e) => e.objectField !== field);
    },
    //
    async goToNextStep() {
      if (this.activeStep === 'role') {
        this.roleError = !this.activeRole;

        if (!this.roleError) {
          this.activeStep = this.activeRole === 'parent' ? 'parent_info' : 'student_info';
        }
        return;
      }

      if (this.activeStep === 'parent_info') {
        this.validateParentInfo();

        const registered = await this.signUp();
        if (!registered) return;

        if (this.parentInfoValid) {
          this.activeStep = 'parent_child_info';
        }
        return;
      }

      if (this.activeStep === 'parent_child_info') {
        this.validateParentChildInfo();

        if (this.parentChildInfoValid) {
          await this.updateRegistrationLead();

          if (this.webinarHasStarted) {
            this.closeModal();
          } else {
            this.activeStep = 'success';
          }
        }

        return;
      }

      if (this.activeStep === 'student_info') {
        this.validateStudentInfo();

        const registered = await this.signUp();
        if (!registered) return;

        if (this.studentInfoValid) {
          this.activeStep = 'student_parent_info';
        }
        return;
      }

      if (this.activeStep === 'student_parent_info') {
        this.validateStudentParentInfo();

        if (this.studentParentInfoValid) {
          await this.updateRegistrationLead();

          if (this.webinarHasStarted) {
            this.closeModal();
          } else {
            this.activeStep = 'success';
          }
        }
      }
    },
    goToPrevStep() {
      if (this.activeTab === 'login') {
        this.rememberPassword = false;
        this.rememberSuccess = false;
        return;
      }

      if (this.activeStep === 'parent_info') {
        this.activeStep = 'role';
        return;
      }
      if (this.activeStep === 'parent_child_info') {
        this.activeStep = 'parent_info';
        return;
      }
      if (this.activeStep === 'student_info') {
        this.activeStep = 'role';
        return;
      }
      if (this.activeStep === 'student_parent_info') {
        this.activeStep = 'student_info';
      }
    },
    //
    async login(user) {
      await this.$store.dispatch('login', user);
      await this.signUpForWebinar();
      this.closeModal();
    },

    pagesLandCode() {
      const category = this.categories.find(({ slug }) => (slug === this.$route.params.material_category));
      return category?.landCode || pagesLand[this.$route.name];
    },
    async signUp() {
      if (this.sending) return false;

      const registerStudent = this.activeStep === 'student_info';

      const registerBody = {
        landCode: this.pagesLandCode,
      };

      if (!registerStudent) {
        registerBody.name = this.parentName;
        registerBody.phone = this.parentPhone;
        registerBody.email = this.parentEmail;
      }

      if (registerStudent) {
        registerBody.classNumber = this.studentParallel;
        registerBody.name = this.studentName;
        registerBody.phone = this.studentPhone;
        registerBody.email = this.studentEmail;
      }

      this.sending = true;
      const response = await this.$api.register(registerBody);
      this.sending = false;

      if (!response.success) {
        this.registeredErrors = response.errors;
        return false;
      }

      const updateResponse = await this.$api.updateAccount({
        name: registerBody.name,
        type: registerStudent ? 'student' : 'parent',
      });

      if (!updateResponse.success) {
        return false;
      }

      this.registeredUser = registerStudent ? updateResponse.parent : updateResponse.student;

      return true;
    },
    async updateRegistrationLead() {
      localStorage.removeItem('lastLeadComment');
      await this.$store.dispatch('login', this.registeredUser);
      await this.signUpForWebinar();

      let comment = null;
      const registerStudent = this.activeStep === 'student_parent_info';
      if (!registerStudent) {
        // eslint-disable-next-line max-len,vue/max-len
        comment = `Информация о ребенке: Имя - ${this.parentChildName}, Почта - ${this.parentChildEmail}, Класс - ${this.parentChildParallel},
        `;
      }

      if (registerStudent) {
        // eslint-disable-next-line max-len,vue/max-len
        comment = `Информация о родителе: Имя - ${this.studentParentName}, Тел - ${this.studentParentPhone}, Почта - ${this.studentParentEmail},
        `;
      }
      await this.$api.updateLead(comment);
      return true;
    },
    signUpForWebinar() {
      return this.$api.manageUserParticipationInPublicWebinar(this.getRegistrationWebinarData.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.m-btn {
  position: static;

  &:focus-visible img {
    outline: 1px solid #000000;
  }
}

.wr-modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;

  &__inner {
    position: relative;
    z-index: 1;
    width: 610px;
    padding: 40px;
    box-sizing: border-box;

    border: 2px solid $black;
    border-radius: 20px;
    background-color: $white-color;
    box-shadow: 4px 4px 0 0 $black-color;

    transition: width .2s ease-out, padding .2s ease-out;

    @include media-down(tablet) {
      width: 344px;
      max-width: 100%;
      padding: 40px 8px 32px 8px;

      border-radius: 16px;
    }

    &--success {
      width: 588px;

      @include media-down(tablet) {
        width: 334px;
        padding: 32px 24px;
      }
    }
  }

  &__back {
    position: absolute;
    left: 0;
    top: 0;

    cursor: pointer;
  }

  &__close {
    position: absolute;
    right: 0;
    top: 0;

    cursor: pointer;
  }

  &__tabs {
    padding: 0 26px;
    display: flex;
    align-items: center;
    justify-content: center;

    border-bottom: 2px solid $black;

    @include media-down(tablet) {
      gap: 2px;
      padding: 0 22px;

      border-bottom: none;
    }

    &--btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      min-width: 50%;
      height: 70px;
      box-sizing: border-box;
      position: relative;

      border: 2px dashed $black;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      border-bottom: none;
      background-color: $white-color;

      font-size: 35px;
      font-weight: 500;
      line-height: 100%;
      text-align: center;
      color: $black;

      cursor: pointer;
      transition: border .2s ease-out, background-color .2s ease-out;

      @include media-down(tablet) {
        height: 44px;

        border-radius: 16px;
        border: 2px dashed $black;

        font-size: 18px;
        font-weight: 600;
        line-height: 16.2px;
      }

      &--active {
        border: 2px solid $black;
        border-bottom: none;
        background-color: #FBD277;
        z-index: 1;

        @include media-down(tablet) {
          border: 2px solid $black;
        }
      }

      &:nth-of-type(1) {
        margin-right: -1px;

        @include media-down(tablet) {
          margin-right: 0;
        }
      }
      &:nth-of-type(2) {
        margin-left: -1px;

        @include media-down(tablet) {
          margin-left: 0;
        }
      }
    }
  }

  &__title {
    margin: 30px auto -6px;
    max-width: 429px;

    font-size: 40px;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: -0.03em;
    text-align: center;
    color: $black;

    @include media-down(tablet) {
      margin: 24px auto -4px;
      max-width: 270px;

      font-size: 24px;
    }
  }

  &__step {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    @include media-down(tablet) {
      margin-top: 24px;
      gap: 8px;
    }
  }

  .form__input {
    ::v-deep input:not(.phone-field__input) {
      width: 100%;
      color: $black;
      font-size: 20px;
      letter-spacing: 0.4px;
      border-radius: 15px;
      border: 2px solid $black;
      padding: 20px 18px 22px;
      height: auto;

      @include media-down(mobile) {
        padding: 16px 14px 17px;
        font-size: 18px;
        letter-spacing: 0.36px;
      }
    }
  }

  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100%;
    height: 70px;
    box-sizing: border-box;
    margin-top: 20px;

    border: 2px solid $black;
    border-radius: 15px;
    background-color: #B6B6FF;
    box-shadow: 0 0 0 0 $black;

    font-size: 20px;
    font-weight: 700;
    line-height: 26.86px;
    text-align: center;
    color: $black;

    cursor: pointer;
    transition: transform .2s ease-out, box-shadow .2s ease-out;

    @include media-down(tablet) {
      height: 60px;
      margin-top: 8px;

      box-shadow: 2px 4px 0 0 $black;

      font-size: 18px;
    }

    &:hover {
      box-shadow: 2px 4px 0 0 $black;
      transform: translate(-2px, -4px);

      @include media-down(tablet) {
        box-shadow: 0 0 0 0 $black;
        transform: translate(2px, 4px);
      }
    }
  }

  &__law {
    margin-top: 4px;

    font-size: 18px;
    font-weight: 600;
    line-height: 100%;
    text-align: center;
    color: $black;

    a {
      color: #73B0F4;
    }
  }

  &__success {
    h5 {
      font-size: 40px;
      font-weight: 700;
      line-height: 100%;
      letter-spacing: -0.03em;
      text-align: center;

      @include media-down(tablet) {
        font-size: 24px;
      }
    }

    p {
      margin-top: 20px;

      font-size: 24px;
      font-weight: 500;
      line-height: 130%;
      text-align: center;

      @include media-down(tablet) {
        margin-top: 16px;

        font-size: 20px;
        line-height: 100%;
      }
    }
  }

  &__login {
    ::v-deep .fieldset {
      input {
        width: 100%;
        border-radius: 15px;
        border: 2px solid $black;
        height: auto;
        padding: 20px 18px 22px;

        color: $black;
        font-family: 'TildaSans', sans-serif;
        font-size: 20px;
        letter-spacing: 0.4px;

        @include media-down(tablet) {
          padding: 16px 14px 17px;
          font-size: 18px;
          letter-spacing: 0.36px;
        }

        &::placeholder {
          color: rgba($black, 0.9);
        }
      }
    }

    ::v-deep button[type='submit'] {
      width: 100%;
      border-radius: 15px;
      border: 2px solid $black;
      background-color: #b6b6ff;
      padding: 20px 18px 23px;

      color: $black;
      font-variant-numeric: lining-nums proportional-nums;
      font-size: 20px;
      font-weight: 700;

      @include media-down(tablet) {
        font-size: 18px;
        font-weight: 700;
        padding: 16px 10px 19px;
      }
    }

    &--success {
      margin-top: 30px;
    }
  }

  &__overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    inset: 0;
    background-color: rgba(#02120F, 0.3);
    backdrop-filter: blur(5px);
    cursor: pointer;
  }
}
</style>
