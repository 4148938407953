<template>
  <div :class="['select-field', {'select-field--error': error}]">
    <v-select
      :value="value"
      :items="items"
      input-value="value"
      :label="placeholder"
      drop-down-image="/v2/common/arrow-down.svg"
      :class="{'select-error': error}"
      @change="onUpdate"
    />
  </div>
</template>

<script>
import VSelect from '@/components/common/vSelect.vue';

export default {
  name: 'FormSelectField',
  components: {
    VSelect,
  },
  props: {
    value: {
      type: [Object, String, Number],
      default: null,
    },
    items: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: '',
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onUpdate(newVal) {
      this.$emit('update', newVal);
    },
  },
};
</script>

<style lang="scss" scoped>
.select-field {
  ::v-deep .select_wrapper {
    width: 100%;
    padding: 24.5px 20px;
    box-sizing: border-box;

    border: 2px solid $black;
    border-radius: 15px;

    @include media-down(tablet) {
      padding: 19px 16px;
    }

    .select_inner {
      .select_result {
        font-size: 24px;
        font-weight: 500;
        line-height: 100%;
        text-align: left;
        color: $black;

        @include media-down(tablet) {
          font-size: 18px;
          font-weight: 600;
        }
      }
    }

    .select_dropdown {
      top: 63px;
      left: -2px;
      width: calc(100% + 4px);
      max-height: 166px;
      overflow: auto;

      border: 2px solid $black;
      border-top: none;
      border-radius: 0 0 15px 15px;

      @include media-down(tablet) {
        top: 46px;
      }

      .select_item {
        padding: 10px 20px;
        font-size: 20px;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.01em;
        text-align: left;

        @include media-down(tablet) {
          padding: 12px 16px;
          font-size: 18px;
          font-weight: 600;
        }

        &:hover {
          background-color: $gray-bg;
        }

        &--selected {
          color: #FA5019;
        }
      }
    }
  }

  &--error {
    ::v-deep .select_wrapper {
      border: 2px solid #ff0040;
    }
  }

  .select-error {
    ::v-deep .select_dropdown {
      border: 2px solid #ff0040;
      border-top: none;
    }
  }
}
</style>
