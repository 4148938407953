const VISIT_SOURCE_KEY = 'visitSource';
const VISIT_UTM_KEY = 'visitUtmSource';

function isValidJSONAndArray(value) {
  if (!value) {
    return false;
  }

  try {
    const parsed = JSON.parse(value);
    return Array.isArray(parsed);
  } catch (e) {
    return false;
  }
}

export const setVisitSource = (source) => {
  localStorage.setItem(VISIT_SOURCE_KEY, source);
};

export const setVisitUtm = (utm) => {
  localStorage.setItem(VISIT_UTM_KEY, utm);
};

export const getVisitHistory = () => {
  const visitSource = localStorage.getItem(VISIT_SOURCE_KEY);
  const visitUtmSource = localStorage.getItem(VISIT_UTM_KEY);
  const internalTransitionsHistory = localStorage.getItem('internalTransitionsHistory');

  if (visitSource || visitUtmSource || internalTransitionsHistory) {
    const visitHistory = [];
    const visitHistoryData = {
      datetime: new Date(),
      internal: false,
    };
    if (visitSource) {
      visitHistoryData.source_page = visitSource;
    }
    if (visitUtmSource) {
      visitHistoryData.utm_source = visitUtmSource;
    }
    visitHistory.push(JSON.stringify(visitHistoryData));

    if (internalTransitionsHistory && isValidJSONAndArray(internalTransitionsHistory)) {
      const internalTransitionsHistoryArr = JSON.parse(internalTransitionsHistory);

      internalTransitionsHistoryArr.forEach((item) => {
        visitHistory.push(JSON.stringify({
          ...item,
          internal: true,
        }));
      });
    }

    return visitHistory;
  }

  return [];
};

export const clearVisitHistory = () => {
  localStorage.removeItem(VISIT_SOURCE_KEY);
  localStorage.removeItem(VISIT_UTM_KEY);
};

export default {
  setVisitSource,
  setVisitUtm,
  getVisitHistory,
  clearVisitHistory,
};
