/*
  Это функция-конструктор для создания миксина отправки лида с различных форм.

  В качестве аргументов функции передается объект со следующими ключами:
    * needName - булевый флаг, который определяет есть ли в форме поле имени,
      по умолчанию установлен в true, поскольку почти во всех формах есть имя;
    * needEmail - булевый флаг, который определяет есть ли в форме поле электронной почты,
      по умолчанию установлен в false;
    * needPhone - булевый флаг, который определяется есть ли в форме поле телефона,
      по умолчанию установлен в false;
    * needAgree - булевый флаг, в некоторых формах есть чекбокс согласия на обработку персональных данных,
      этот флажок определяет необходимость под создание состояния для него и участие в валидации формы,
      по умолчанию установлен в false;
    * needToSendTitle - булевый флаг, в некоторых формах необходимо отправлять заголовок формы в лиде,
      если этот флаг в true, то этот заголовок добавляется в данные для отправки, для избежания ошибок - он
      добавлен в этом миксине в computed по ключу titleToSend, такой computed необходимо сделать в компоненте,
      из которого предполагается отправка;
    * needToSendComments - булевый флаг, в некоторых формах необходимо отправлять комментарий в лиде,
      если этот флаг в true, то этот комментарий добавляется в данные для отправки, для избежания ошибок - он
      добавлен в этом миксине в computed по ключу commentsToSend, такой computed необходимо сделать в компоненте,
      из которого предполагается отправка;

  Функция создает объект состояния data, в который по умолчанию добавляются следующие ключи:
    * nameInput - ключ для хранения состояния поля имени;
    * submitError - ключ для хранения массива с ошибками;
    * validForm - ключ для хранения состояния валидности формы;
    * sending - ключ для хранения состояния отправки формы;
  Опционально в data записываются needName, needPhone, phoneInput, needEmail, emailInput, needAgree, agree,
   - по тем флагам, что мы передаем при создании миксина.

  Методы миксина.
  У миксина есть следующие методы: clearSubmitError, checkValidate - внутренние методы для очистки ошибок
  и валидации формы по тем ref, которые мы передали при создании и метод submit.
  Метод submit - метод отправки лида. Он формирует объект с данными для отправки лида,
  объект формируется из состояний полей и landCode.
  landCode берется при помощи следующей конструкции - this.landCode || pagesLand[this.$route.name].
  Если в родительском компоненте нам нужно передать специфичный landCode или изменчивый landCode по каким-либо условиям,
  то мы можем записать его в data или computed по ключу landCode.
  Кроме того метод submit предусматривает возможность различной обработки успешного выполнения отправки лида.
  Если в родительском компоненте есть метод onSubmit - то вызывается этот метод, если нет,
  то по дефолту пользователя перекидывает на страницу '/thanks'.

  Внедрение миксина в компонент с формой.
  sendLeadMixinConstructor - это функция-конструктор, она импортируется в компонент
  и при помощи нее создается новый миксин, например:
    const sendLeadMixin = sendLeadMixinConstructor({
      needPhone: true,
      needEmail: true,
    });
  Затем этот миксин добавляется в миксины компонента - mixins: [sendLeadMixin]
  Для корректной работы валидации поля в компоненте должны иметь следующие ref:
    * поле имени - nameField;
    * поле телефона - phoneField;
    * поле почты - emailField;
  Поля в компоненте хранят состояние в строго заданных ключах:
    * поле имени в nameInput;
    * поле почты в emailInput;
    * поле телефона в phoneInput;
    * поле согласия на обработку данных в agree;
  Если в какой-то форме нам важно отслеживать процесс отправки лида (например,
  чтобы блокировать кнопку отправки во время обработки), то мы можем делать это по ключу sending.
*/
import pagesLand from '~/data/pagesLand';
import { getQuestionnairePath } from '@/utils/questionnairePath';
import { getVisitHistory, clearVisitHistory } from '@/utils/visitHistory';
import { mapGetters } from 'vuex';

export default function sendLeadMixinConstructor({
  needName = true,
  needToValidateName = true,
  needEmail = false,
  needPhone = false,
  needCommentPhone = false,
  needAgree = false,
  needToSendTitle = false,
  needToSendComments = false,
} = {}) {
  return {
    data() {
      const sendLeadData = {
        submitError: [],
        validForm: true,
        sending: false,
        needToSendTitle,
        needToSendComments,
      };
      if (needName) {
        sendLeadData.nameInput = '';
        sendLeadData.needName = true;
      }
      if (needToValidateName) {
        this.needToValidateName = true;
      }
      if (needEmail) {
        sendLeadData.emailInput = '';
        sendLeadData.needEmail = true;
      }
      if (needPhone) {
        sendLeadData.phoneInput = '';
        sendLeadData.needPhone = true;
      }
      if (needCommentPhone) {
        sendLeadData.phoneCommentInput = '';
        sendLeadData.needCommentPhone = true;
      }
      if (needAgree) {
        sendLeadData.agree = true;
        sendLeadData.needAgree = true;
      }
      return sendLeadData;
    },
    computed: {
      ...mapGetters({
        categories: 'getMaterialCategories',
      }),
      titleToSend() {
        return '';
      },
      commentsToSend() {
        return '';
      },
      mixinLandCode() {
        const materialCategoryLandCode = this.categories
          .find(({ slug }) => slug === this.$route.params?.material_category)
          ?.landCode;

        return this.componentLandCode
          || this.landCode
          || materialCategoryLandCode
          || pagesLand[this.$route.name]
          || pagesLand[this.$route.path];
      },

      /**
       * Для использования отправки других запросов с этими данными
       */
      sendLeadBody() {
        return {
          name: this.nameInput || '',
          phone: (this.phoneInput || '').replace('+', ''),
          email: this.emailInput || '',
          landCode: this.mixinLandCode,
        };
      },
    },
    mounted() {
      /* eslint-disable no-console,max-len,vue/max-len */
      if (this.needName && !this.$refs.nameField) {
        console.error('Поле name: не добавлен ref для поля или не правильное название ref`а. Должен быть "nameField"');
      }
      if (this.needEmail && !this.$refs.emailField) {
        console.error('Поле email: не добавлен ref для поля или не правильное название ref`а. Должен быть "emailField"');
      }
      if (this.needPhone && !this.$refs.phoneField) {
        console.error('Поле phone: не добавлен ref для поля или не правильное название ref`а. Должен быть "phoneField"');
      }
      if (this.needCommentPhone && !this.$refs.phoneCommentField) {
        console.error('Поле comment phone: не добавлен ref для поля или не правильное название ref`а. Должен быть "phoneCommentField"');
      }
      /* eslint-enable */
    },
    methods: {
      clearSubmitError(field) {
        this.submitError = this.submitError.filter((e) => e.objectField !== field);
      },
      checkValidate() {
        this.validForm = true;
        let valid = true;

        if (this.needName && this.needToValidateName && !this.$refs.nameField.validate()) {
          valid = false;
        }

        if (this.needEmail && !this.$refs.emailField.validate()) {
          valid = false;
        }

        if (this.needPhone && !this.$refs.phoneField.validate()) {
          valid = false;
        }

        if (this.needCommentPhone && !this.$refs.phoneCommentField.validate()) {
          valid = false;
        }

        if (this.needCommentPhone && this.phoneCommentInput && this.phoneCommentInput === this.phoneInput) {
          valid = false;
          this.submitError = [{ objectField: 'phone', message: 'Номера совпадают' }];
        }

        if (this.needAgree && !this.agree) {
          valid = false;
        }

        this.validForm = valid;

        return valid;
      },
      goToQuestionnaire() {
        this.$router.push(getQuestionnairePath(this.$route));
      },
      async submit() {
        if (!this.checkValidate()) {
          return false;
        }
        if (this.sending) {
          return false;
        }

        this.sending = true;

        // eslint-disable-next-line
        const sendLeadBody = this.sendLeadBody;

        if (this.needToSendTitle) {
          sendLeadBody.formTitle = this.titleToSend
            .replaceAll('&nbsp;', ' ')
            .replaceAll(/<[^>]*>/g, '');
        }
        if (this.needToSendComments) {
          sendLeadBody.comments = this.commentsToSend;
        }

        if (this.needCommentPhone) {
          sendLeadBody.comments = `Телефон ученика: ${this.phoneCommentInput}`;
        }

        sendLeadBody.visitHistory = getVisitHistory();

        this.sendMegafonApplication();
        let response = {};

        if (this.finalizeLead) {
          response = await this.$api.finalizeLead(sendLeadBody);
        } else {
          response = await this.$api.sendLead(sendLeadBody);
        }

        if (response.success) {
          this.$vkPixel.reachGoal('Lead');

          clearVisitHistory();

          if (this.onSubmit) {
            this.onSubmit();
          } else {
            this.goToQuestionnaire();
          }
        } else {
          this.validForm = false;
          this.submitError = response.errors;
        }
        this.sending = false;
        return true;
      },
      async signUp() {
        if (!this.checkValidate()) {
          return false;
        }
        if (this.sending) {
          return false;
        }

        this.sending = true;

        const registerBody = this.sendLeadBody;
        if (this.needToSendTitle) {
          registerBody.formTitle = this.titleToSend
            .replaceAll('&nbsp;', ' ')
            .replaceAll(/<[^>]*>/g, '');
        }

        if (this.needCommentPhone) {
          registerBody.comment = `Телефон ученика: ${this.phoneCommentInput}`;
        }

        const response = await this.$api.register(registerBody);

        if (response.success) {
          await this.$store.dispatch('login', response.student);

          if (this.onRegisterSubmit) {
            this.onRegisterSubmit(response);
          } else if (this.onSubmit) {
            this.onSubmit(response);
          } else {
            this.goToQuestionnaire();
          }
        } else if (this.onRegisterSubmitError) {
          this.onRegisterSubmitError(response);
        } else {
          this.submit();
        }

        this.sending = false;
        return true;
      },

      clearFields() {
        if (this.$refs.nameField) {
          this.nameInput = '';
          this.$refs.nameField.reset();
        }
        if (this.$refs.phoneField) {
          this.phoneInput = '';
          this.$refs.phoneField.reset();
        }
        if (this.$refs.phoneCommentField) {
          this.phoneCommentInput = '';
          this.$refs.phoneCommentField.reset();
        }
        if (this.$refs.emailField) {
          this.emailInput = '';
          this.$refs.emailField.reset();
        }
      },

      getProductNameForMegafon() {
        // 1
        if (
          this.$route.name === 'index'
          || this.$route.name.includes('online-school')
          || this.$route.name === 'halloween'
          || this.$route.name === 'externat'
          || this.$route.name === 'happy-new-year-2024'
        ) {
          return 'school_synergy_ru';
        }

        // 2
        if (this.$route.name.includes('marathon-') || this.$route.name.includes('promo-')) {
          return 'new_online_school_marathon_ege';
        }

        // 3
        if (
          this.$route.name === 'oge'
          || this.$route.name === 'oge-subpage'
          || this.$route.name === 'itogovoe-sobesedovanie-2024'
        ) {
          return 'new_online_school_oge';
        }

        // 4
        if (
          this.$route.name === 'ege'
          || this.$route.name === 'ege-subpage'
          || this.$route.name === 'itogovoe-sochinenie'
        ) {
          return 'new_online_school_ege';
        }

        // 5
        if (this.$route.name.includes('repetitor')) {
          return 'new_online_school_repetitor';
        }

        // 6
        if (this.$route.name === 'soft-skills') {
          return 'new_online_school_soft_skills';
        }

        return null;
      },
      sendMegafonApplication() {
        const product = this.getProductNameForMegafon();

        if (product) {
          const script = document.createElement('script');
          // eslint-disable-next-line max-len,vue/max-len
          script.src = `https://js.onef.pro/static/reg1f_v1.js?1f_pixel_id=24aed672-c5f3-4e78-ba7e-eed74b860cd1&event_type=application&product=${product}`;
          script.defer = true;
          document.head.appendChild(script);
        }
      },
    },
  };
}
