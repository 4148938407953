const SESSION_STORAGE_KEY = 'online-school-utm';

export const saveUtm = (route) => {
  if (!localStorage) return;

  const queryKeys = Object.keys(route?.query || {});

  if (queryKeys.length) {
    const haveUtmData = queryKeys.some((queryKey) => /^utm_/.test(queryKey));

    if (haveUtmData) {
      localStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(route.query));
    }
  }
};

/**
 * если есть в url, то сразу возвращаем оттуда.
 * с localStorage иногда возникали проблемы,
 * для надежности, при наличии, берем из url
 */
export const getUtm = (route) => {
  if (route) {
    const queryKeys = Object.keys(route?.query || {});
    if (queryKeys.length) {
      const haveUtmData = queryKeys.some((queryKey) => /^utm_/.test(queryKey));
      if (haveUtmData) {
        return route.query;
      }
    }
  }

  if (localStorage) {
    const utm = localStorage.getItem(SESSION_STORAGE_KEY);
    if (utm) {
      return JSON.parse(utm);
    }
  }

  return {};
};

export const removeUtm = () => {
  if (!localStorage) return;
  localStorage.removeItem(SESSION_STORAGE_KEY);
};

export default { saveUtm, getUtm, removeUtm };
